import React, { useEffect, useRef, useState } from 'react';
import { Container, Typography, CircularProgress, Box } from '@mui/material';
import MiniAppCard from '../components/MiniAppCard';
import axios from 'axios';
import MoreCard from './MoreCard';
import { useParams } from 'react-router-dom';
import HeaderApp from '../components/HeaderApp';
import ScrollApp from '../components/ScrollApp';
import ScrollList from '../components/ScrollList';

const bots = {
    curtaflix: "curtaflix_bot",
    arteflix: "arteflix_bot",
    dramastvshop: "doramastv_bot"
};

const Miniapp = () => {
    const [categories, setCategories] = useState({
        "Mais Assistidos": [],
        "Lançamentos": [],
        "Dublados": []
    });
    const { bot } = useParams();
    const [loading, setLoading] = useState(true);
    const [optionClick, setOptionClick] = useState(null);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState('');

    const fetchMovies = async () => {
        setOptionClick("home");
        try {
            setLoading(true);
            const responses = await Promise.all([
                axios.get(`TopWatched`, { params: { days: 15, limit: 20, bot } }),
                axios.get(`getMoviesNewlyAdded`, { params: { limit: 20 } }),
                axios.get(`TopWatched`, { params: { days: 60, limit: 20, bot } }),
            ]);
            setCategories({
                "Em Alta": responses[0].data.videos,
                "Lançamentos": responses[1].data.videos,
                "Mais Assistidos": responses[2].data.videos
            });
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMovies();
    }, []);

    useEffect(() => {
        if (debouncedQuery === "") {
            setOptionClick("home")
        } else {
            setOptionClick("search")
        }
    }, [debouncedQuery]);

    useEffect(() => {
        console.log("foi searchQuery")
        const handler = setTimeout(() => setDebouncedQuery(searchQuery), 1000);
        return () => clearTimeout(handler);
    }, [searchQuery]);

    const handleCategoryClick = (category) => {
        console.log(category);
        setSearchQuery(category);
    };

    const handleMyListClick = () => {
        console.log("my_list click");
        setOptionClick("my_list")
    };

    const getOptionComponents = (option) => {
        console.log("my_list click");
        if (option === "my_list") {
            return (<ScrollList loading={loading} bot={bot} setLoading={setLoading} setError={setError} debouncedQuery={debouncedQuery} setDebouncedQuery={setDebouncedQuery} searchQuery={searchQuery} />)
        } else if (option === "search") {

            return (<ScrollApp loading={loading} bot={bot} setLoading={setLoading} setError={setError} debouncedQuery={debouncedQuery} setDebouncedQuery={setDebouncedQuery} searchQuery={searchQuery} />)

        } else if (option === "home") {
            return (<Box
                sx={{
                    flexGrow: 1,
                    height: 'calc(100vh - 170px)',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': { display: 'none' },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                    padding: '10px',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {Object.keys(categories).map((category) => (
                    <Box key={category} sx={{
                        mt: 2,
                        paddingLeft: 2
                    }}>
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                            {category}
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            gap: 1,
                            paddingBottom: 2,
                            '&::-webkit-scrollbar': { height: '6px' },
                            '&::-webkit-scrollbar-thumb': { backgroundColor: 'red', borderRadius: '4px' }
                        }}>
                            {categories[category].length > 0 ? (
                                <>
                                    {categories[category].map(movie => (
                                        <Box key={movie.id} sx={{ flex: '0 0 auto', width: 155 }}>
                                            <MiniAppCard bot={bots[bot]} movie={movie} />
                                        </Box>
                                    ))}

                                    {/* Card de "Ver Mais" */}
                                    <Box sx={{ flex: '0 0 auto', width: 100 }}>
                                        <MoreCard onClick={() => handleCategoryClick(category)} />
                                    </Box>
                                </>
                            ) : (
                                <Typography color="gray">Nenhum filme encontrado.</Typography>
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>)
        }
    };

    /*     const handleNewOrder = () => {
            setMenuOpen(false);
            const url = `https://t.me/${bots[bot]}?start=newOrder`;
            window.location.href = url;
        };
    
        const handleClickSuport = () => {
            setMenuOpen(false);
            const url = `https://t.me/${bots[bot]}?start=help`;
            window.location.href = url;
        }; */


    return (
        <Container sx={{ minHeight: '100vh', padding: 0, backgroundColor: '#141414', color: 'white' }}>
            {/* Barra de Navegação */}

            <HeaderApp handleCategoryClick={handleCategoryClick} handleMyListClick={handleMyListClick} setSearchQuery={setSearchQuery} searchQuery={searchQuery} debouncedQuery={debouncedQuery} bot={bot} />

            {/* Exibir Erros */}
            {error && (
                <Typography variant="h6" sx={{ textAlign: 'center', color: 'error.main', mt: 4 }}>
                    Erro: {error}
                </Typography>
            )}
            {
                optionClick && getOptionComponents(optionClick)
            }

            {/* Seções Horizontais */}
            {/* debouncedQuery != '' ? (
                <ScrollApp loading={loading} bot={bot} setLoading={setLoading} setError={setError} debouncedQuery={debouncedQuery} setDebouncedQuery={setDebouncedQuery} searchQuery={searchQuery} />
            ) : (
                <Box
                    sx={{
                        flexGrow: 1,
                        height: 'calc(100vh - 170px)',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': { display: 'none' },
                        '-ms-overflow-style': 'none',
                        'scrollbar-width': 'none',
                        padding: '10px',
                        marginTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {Object.keys(categories).map((category) => (
                        <Box key={category} sx={{
                            mt: 2,
                            paddingLeft: 2
                        }}>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                                {category}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                overflowX: 'auto',
                                whiteSpace: 'nowrap',
                                gap: 1,
                                paddingBottom: 2,
                                '&::-webkit-scrollbar': { height: '6px' },
                                '&::-webkit-scrollbar-thumb': { backgroundColor: 'red', borderRadius: '4px' }
                            }}>
                                {categories[category].length > 0 ? (
                                    <>
                                        {categories[category].map(movie => (
                                            <Box key={movie.id} sx={{ flex: '0 0 auto', width: 155 }}>
                                                <MiniAppCard bot={bots[bot]} movie={movie} />
                                            </Box>
                                        ))}

                                        <Box sx={{ flex: '0 0 auto', width: 100 }}>
                                            <MoreCard onClick={() => handleCategoryClick(category)} />
                                        </Box>
                                    </>
                                ) : (
                                    <Typography color="gray">Nenhum filme encontrado.</Typography>
                                )}
                            </Box>
                        </Box>
                    ))}
                </Box>
            ) */}

            {/* <Box sx={{ position: 'fixed', bottom: 20, right: 20 }}>
                <TelegramButton phoneNumber={pageConfig.phoneNumber} message={"Olá, gostaria de fazer um pedido!"} />
            </Box> */}
        </Container>
    );
};

export default Miniapp;