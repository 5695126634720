import React from 'react';
import { Card, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CustomCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 150,
  height: 220,
  borderRadius: '8px',
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.2s',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(0, 0, 0, 0.4)',
  cursor: 'pointer',
  '&:active': {
    transform: 'scale(0.95)',
  },
}));

const Overlay = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  background: 'rgba(0, 0, 0, 0.3)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px',
  height: '40px',
});

const MoreCard = ({ onClick }) => {
  return (
    <CustomCard onClick={onClick}>
      <Box
        sx={{
          width: 60,
          height: 60,
          borderRadius: '50%',
          backgroundColor: 'rgba(255, 0, 0, 0.8)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'background 0.3s ease',
          '&:hover': {
            backgroundColor: 'rgba(255, 0, 0, 1)',
          },
        }}
      >
        <Typography variant="h2" sx={{ color: 'white', fontWeight: 'bold' }}>
          +
        </Typography>
      </Box>
      <Overlay>
        <Typography
          variant="body2"
          sx={{
            color: 'white',
            textAlign: 'center',
            height: '32px',
            lineHeight: '16px',
            overflow: 'hidden',
            display: 'block',
            whiteSpace: 'normal',
            textOverflow: 'ellipsis',
          }}
        >
          Ver Mais
        </Typography>
      </Overlay>
    </CustomCard>
  );
};

export default MoreCard;
