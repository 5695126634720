import { Alert, AppBar, Box, Drawer, IconButton, List, ListItem, ListItemText, Snackbar, TextField, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';


const HeaderApp = ({ setSearchQuery, searchQuery, debouncedQuery, bot, handleMyListClick = () => { } }) => {


  const [menuOpen, setMenuOpen] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const showDevelopmentAlert = () => {
    setAlertOpen(true);
  };
  const handleCategoryClick = (category) => {
    setMenuOpen(false);
    setSearchQuery(category);
  };

  const handleListClick = () => {
    setMenuOpen(false);
    handleMyListClick();
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#141414' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ width: 20 }}>
            {debouncedQuery != '' && <IconButton onClick={() => setSearchQuery("")} sx={{ color: 'white' }}>
              <ArrowBackIcon />
            </IconButton>}

          </Box>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'red' }}>
            {bot.toUpperCase()}
          </Typography>
          <IconButton color="inherit" onClick={() => setMenuOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer Menu */}
      <Snackbar open={alertOpen} autoHideDuration={4000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity="info" sx={{ width: '100%' }}>
          Esta funcionalidade está em desenvolvimento!
        </Alert>
      </Snackbar>
      <Drawer anchor="left" open={menuOpen} onClose={() => setMenuOpen(false)}>
        <List sx={{ width: 250, backgroundColor: '#1e1e1e', height: '100%', color: 'white' }}>
          <ListItem>
            <IconButton onClick={() => setMenuOpen(false)} sx={{ color: 'white' }}>
              <ArrowBackIcon />
            </IconButton>
          </ListItem>
          <ListItem key={1} onClick={() => {
            setMenuOpen(false);
            setSearchQuery("")
          }}>
            <ListItemText primary="Inicio" />
          </ListItem>
          <ListItem key={2}>
            <ListItemText onClick={handleListClick} primary="Minha Lista" />
          </ListItem>
          <ListItem key={4}>
            <ListItemText onClick={() => handleCategoryClick("Em Alta")} primary="Em Alta" />
          </ListItem>
          <ListItem key={5}>
            <ListItemText onClick={() => handleCategoryClick("Lançamentos")} primary="Lançamentos" />
          </ListItem>
          <ListItem key={6}>
            <ListItemText onClick={() => handleCategoryClick("Mais Assistidos")} primary="Mais Assistidos" />
          </ListItem>
        </List>
      </Drawer>

      {/* Campo de Pesquisa */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, paddingX: 1.5 }}>
        <TextField
          variant="outlined"
          placeholder="Pesquisar..."
          value={searchQuery}
          onChange={(e) => { console.log(e.target.value); setSearchQuery(e.target.value) }}
          sx={{ width: '100%', maxWidth: 600, backgroundColor: 'white', borderRadius: 1 }}
        />
      </Box>
    </>
  );
};

export default HeaderApp;
