import React, { useEffect, useState, createContext, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import MovieList from './pages/MovieList';
import Miniapp from './pages/Miniapp';
import FetchNumber from './pages/FetchNumber';
import { AuthProvider, useAuth } from './context/AuthContext';
import Register from './pages/Register';
import AuthMovie from './pages/AuthMovie';


const getPageConfig = async () => {
  console.log('window.location.origin0', window.location.origin)
  const domain = window.location.origin.includes('localhost') ? 'https://dramastv.shop' : window.location.origin;
  try {
    const response = await axios.post(`${domain}/backend/server/pageConfig`, { domain });
    return(response.data.urlBackend);
  } catch (error) {
    console.error("Erro ao buscar configuração da página:", error.response?.data || error.message);
    return {};
  }
};

axios.defaults.baseURL = await getPageConfig();
const theme = createTheme();
const PageConfigContext = createContext();
export const usePageConfig = () => useContext(PageConfigContext);

const PageConfigProvider = ({ children }) => {
  const [pageConfig, setPageConfig] = useState({});

  const getPageConfig = async () => {
    // const domain = window.location.origin; // Obtém o domínio atual
    console.log('window.location.origin', window.location.origin)
    const domain = window.location.origin.includes('localhost') ? 'https://dramastv.shop' : window.location.origin;
    try {
      const response = await axios.post(`${domain}/backend/server/pageConfig`, { domain });
      setPageConfig(response.data);

      axios.defaults.baseURL = response.data.urlBackend;
    } catch (error) {
      console.error("Erro ao buscar configuração da página:", error.response?.data || error.message);
    }
  };

  useEffect(() => {
    getPageConfig();
  }, []);

  return (
    <PageConfigContext.Provider value={pageConfig}>
      {children}
    </PageConfigContext.Provider>
  );
};

function PrivateRoute({ children }) {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <PageConfigProvider>
          <Router>
            <Routes>
              <Route path="/" element={<MovieList />} />
              <Route path="/login" element={<Login />} />
              <Route path="/auth/:token" element={<AuthMovie />} />
              <Route path="/register" element={<Register />} />
              <Route path="/miniapp/:bot" element={<Miniapp />} />
              <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/:number" element={<FetchNumber />} />
            </Routes>
          </Router>
        </PageConfigProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
