import React from 'react';
import { Card, CardMedia, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CustomCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 150,
  height: 220,
  borderRadius: '8px',
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.2s',
  '&:active': {
    transform: 'scale(0.95)',
  },
}));


const Overlay = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  background: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px',
  height: '40px',
});

const MiniAppListCard = ({ bot, movie }) => {
  const handleClick = (link) => {
    window.open(link, '_blank');
  };

  const checkInviteLink = async (inviteLink) => {
    const response = await fetch(inviteLink, { method: 'GET' });
    
    // Aqui verificamos a resposta
    console.log("response", response.data)
    if (response.status === 200) {
      console.log('Link válido!');
    } else if (response.status === 403) {
      console.log('O link de convite expirou ou é inválido.');
    } else {
      console.log('Erro ao acessar o link.');
    }
  };

  return (
    <CustomCard onClick={() => handleClick(movie.invite)}>
      <CardMedia
        component="img"
        image={`https://curtaflix.shop/telegram/images/${movie.image}`}
        alt={movie.name}
        sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      <Overlay>
        <Typography
          variant="body2"
          sx={{
            color: 'white',
            textAlign: 'center',
            height: '32px',
            lineHeight: '16px',
            overflow: 'hidden',
            display: 'block',
            whiteSpace: 'normal',
            textOverflow: 'ellipsis',
          }}
        >
          {movie.name.replace(/\.[^/.]+$/, '')}
        </Typography>
      </Overlay>
    </CustomCard>
  );
};

export default MiniAppListCard;
