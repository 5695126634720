import React from 'react';
import { Card, CardMedia, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CustomCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 150,
  height: 220,
  borderRadius: '8px',
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.2s',
  '&:active': {
    transform: 'scale(0.95)',
  },
}));

const PriceTag = styled(Box)({
  position: 'absolute',
  top: 8,
  right: 8,
  background: 'rgba(0, 0, 0, 0.7)',
  color: '#FFD700',
  padding: '4px 8px',
  borderRadius: '4px',
  fontWeight: 'bold',
  fontSize: '12px',
});

const Overlay = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  background: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px',
  height: '40px',
});

const MiniAppCard = ({ bot, movie }) => {
  const handleClick = () => {
    const startParam = `${movie.movieId}`;
    const telegramUrl = `https://t.me/${bot}?start=${startParam}`;

    window.open(telegramUrl, '_blank');
  };

  return (
    <CustomCard onClick={handleClick}>
      <CardMedia
        component="img"
        image={`https://curtaflix.shop/telegram/images/${movie.image}`}
        alt={movie.name}
        sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      <PriceTag>
        R$ {bot.includes("curtaflix") ? movie.price: Number(movie.price)}
      </PriceTag>
      <Overlay>
        <Typography
          variant="body2"
          sx={{
            color: 'white',
            textAlign: 'center',
            height: '32px',
            lineHeight: '16px',
            overflow: 'hidden',
            display: 'block',
            whiteSpace: 'normal',
            textOverflow: 'ellipsis',
          }}
        >
          {movie.name.replace(/\.[^/.]+$/, '')}
        </Typography>
      </Overlay>
    </CustomCard>
  );
};

export default MiniAppCard;
