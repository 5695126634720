import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import MiniAppCard from './MiniAppCard';
import axios from 'axios';


const bots = {
  curtaflix: "curtaflix_bot",
  arteflix: "arteflix_bot",
  dramastvshop: "doramastv_bot"
};


const ScrollApp = ({ loading, bot, setLoading, setError, debouncedQuery }) => {


  const scrollRef = useRef(null);
  const [movies, setMovies] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const fetchMoviesSearch = async (page, query = '') => {
    setLoading(true);
    try {
      const response = await axios.get(`videos`, {
        params: { page, limit: 20, name: query, bot },
      });

      const { videos, totalPages } = response.data;

      if (page === 1) {
        setMovies(videos);
      } else {
        setMovies(prevMovies => [...prevMovies, ...videos]);
      }
      setHasMore(page < totalPages);

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    setPage(1);
    setHasMore(true);
  }, [debouncedQuery]);

  useEffect(() => {
    console.log("foi debouncedQuery")
    fetchMoviesSearch(page, debouncedQuery);
  }, [page, debouncedQuery])

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        if (clientHeight + scrollTop + 1 >= scrollHeight) {
          if (!loading && hasMore) {
            setPage(prevPage => prevPage + 1);
          }
        }
      }
    };

    const container = scrollRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, hasMore]);

  return (
    <>
      {loading && movies.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
          <CircularProgress color="secondary" size={80} />
        </Box>
      ) :

        <Box
          ref={scrollRef}
          sx={{
            flexGrow: 1, // Permite que o scroll ocupe o espaço restante
            height: 'calc(100vh - 170px)', // Ajuste conforme a altura do cabeçalho
            overflowY: 'auto', // Ativa o scroll apenas nesta área
            '&::-webkit-scrollbar': { width: '6px' }, // Estiliza scrollbar
            '&::-webkit-scrollbar-thumb': { backgroundColor: 'red', borderRadius: '4px' },
            '&:hover::-webkit-scrollbar-thumb': { backgroundColor: '#555' },
            padding: '10px',
            marginTop: '20px',
            display: 'flex', // Garante que o flexGrow funcione
            flexDirection: 'column', // Mantém os itens organizados verticalmente
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2, // Espaçamento entre os elementos
            }}
          >
            {!loading && movies.length === 0 ? (
              <Box
                sx={{
                  zIndex: 10,
                }}
              >
                {/* <WhatsAppButton phoneNumber={pageConfig.phoneNumber} message={"Olá, gostaria de fazer um pedido!"} type='button' /> */}
              </Box>
            ) : movies.map(movie => (
              <MiniAppCard bot={bots[bot]} key={movie.movieId} movie={movie} />
            ))}
            {loading && movies.length > 0 && (
              // Spinner ao carregar mais filmes no scroll infinito
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 4,
                  zIndex: 10,
                }}
              >
                <CircularProgress color="secondary" size={60} />
              </Box>
            )}
          </Box>

        </Box>
      }
    </>
  );
};

export default ScrollApp;
